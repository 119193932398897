'use client';
import React, { HTMLInputTypeAttribute, useMemo } from 'react';
import { useFormLoading } from "../context/FormContext";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import {Controller, useFormContext} from "react-hook-form";
import { FormGroup, InputLabel, InputProps, Skeleton, Stack, Tooltip } from '@mui/material';
import Error from '@mui/icons-material/Error';

export interface FormInputProps {
    label: string | React.ReactNode;
    name: string;
    placeholder?: string;
    required?: boolean;
    type?: HTMLInputTypeAttribute;
    InputProps?: InputProps;
    inputLabelProps?: object;
    value?: string;
    TextFieldProps?: TextFieldProps;
    defaultValue?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    loading?: boolean;
    hidden?: boolean;
    error?: string;
    inlineError?: boolean;
    isStatic?: boolean;
}

export const FormInput = (
    {
      label,
      name,
      type = "text",
      placeholder,
      required,
      value,
      TextFieldProps,
      InputProps,
      onChange,
      disabled,
      inputLabelProps, loading, hidden, error,
      inlineError,
      isStatic
  }: FormInputProps) => {

    // FORM CONTEXT
    const {control, formState: { errors }, clearErrors} = useFormContext();
    const isLoading = useFormLoading();

    const computedErrorMessage = useMemo(() => {

        if(TextFieldProps?.helperText === '') {
            return
        }

        if(error) {
            return error
        }

        // try and split to find error
        // Assuming field name is [field] and BE returns [field]
        if(!name.includes('.')) {
            if(errors[name]?.message) {
                return `${errors[name]?.message}`
            }
        }

        // Split name by .'s
        // Assuming field name is [field] but BE returns [field][error array]
        const keys = name.split('.');
        let path = errors?.[keys[0]];
        if(path) {
            for(let i = 1; i < keys.length; i++) {
                // @ts-ignore
                path = path?.[keys[i] as any]
            }

            return path?.message
        }

        return

    }, [error, errors, errors?.[name], inlineError, TextFieldProps?.helperText]);

    const interceptChange = (e: any, changeFunc: any) => {
        changeFunc(e);

        if(errors?.api) {
            clearErrors('api')
        }

        if(error || errors[name] || computedErrorMessage) {
            clearErrors(name);
        }
        if (onChange) {
            onChange(e);
        }
    };

    if(loading) {
        return (
            <FormGroup sx={{ width: '100%', mb: '26px' }}>
                <InputLabel>
                    {label}
                </InputLabel>
                <Skeleton width={'100%'} height={'32px'} variant={'rectangular'} />
            </FormGroup>
        )
    }

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={value || ""}
            render={({ field }) => (
                <FormGroup sx={{ width: '100%' }} tabIndex={isStatic ? -1 : undefined}>
                    {label &&
                        <InputLabel style={ hidden ? {display: 'none'} : {}}>
                            {label}
                        </InputLabel>
                    }
                        <TextField
                            variant={isStatic ? 'standard' : "filled"}
                            {...TextFieldProps}
                            {...field}
                            value={value || field.value || ""}
                            disabled={isLoading || disabled}
                            required={required}
                            type={type}
                            placeholder={placeholder}
                            fullWidth={true}
                            InputLabelProps={inputLabelProps}
                            onChange={isStatic ? undefined : (e) => interceptChange(e, field.onChange)}
                            tabIndex={isStatic ? -1 : undefined}
                            InputProps={{
                                ...TextFieldProps?.InputProps,
                                endAdornment: (
                                    <Stack direction={'row'} gap={.5}>
                                        {TextFieldProps?.InputProps?.endAdornment || InputProps?.endAdornment}
                                        {inlineError && <FormInputInlineError errorMessage={computedErrorMessage as string} />}
                                    </Stack>
                                ),
                                sx: {
                                    ...TextFieldProps?.InputProps?.sx,
                                    px: isStatic ? 0 : undefined,
                                }
                            }}

                            inputProps={{
                                tabIndex: isStatic ? -1 : undefined
                            }}

                            helperText={!inlineError && (computedErrorMessage as string || " ")}
                            error={!!computedErrorMessage}

                            sx={{
                                px: isStatic ? 0 : undefined,
                                ...TextFieldProps?.sx,
                                display: hidden ? 'none' : undefined,
                                pointerEvents: isStatic ? 'none' : undefined,
                            }}
                        />


                </FormGroup>
            )}
        />
    );
};

const FormInputInlineError = ({errorMessage}: { errorMessage: string | undefined }) => {

    if(!errorMessage) {
        return null
    }

    return (
        <Tooltip
            title={errorMessage}
        >
            <Error color='error' fontSize='small' />
        </Tooltip>
    )

}

export default FormInput;
