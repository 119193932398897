export const pageRoutes = {

    // ROOT
    root: '/',

    // AUTH
    auth: {
        login: '/login',
        resetPassword: '/reset-password',
        forgotPassword: '/forgot-password'
    },

    // LEGAL
    legal: {
        privacyPolicy: '/privacy-policy',
        termsOfService: '/terms-of-service'
    },

    // ACCOUNT
    account: {
        root: '/account'
    },

    // DASHBOARD
    dashboard: {
        root: '/dashboard',
        noTeams: '/dashboard/no-teams',
        team: {
            root: (teamId: string | number) => `/dashboard/team/${teamId}`,
            player: {
                root: (teamId: string | number, playerId: string | number, isInit?: boolean) => `/dashboard/team/${teamId}/player/${playerId}${isInit ? '?init=1' : ''}`,
                session: {
                    root: (teamId: string | number, playerId: string | number, sessionId: string | number, isInit?: boolean) => `/dashboard/team/${teamId}/player/${playerId}/session/${sessionId}${isInit ? '?init=1' : ''}`,
                    details: (teamId: string | number, playerId: string | number, sessionId: string | number, searchParams?: URLSearchParams) => `/dashboard/team/${teamId}/player/${playerId}/session/${sessionId}/details${searchParams ? `?${searchParams.toString()}` : ''}`,
                }
            }
        }
    },

    // UPLOAD
    upload: {
        root: '/upload'
    },

    // TEAMS
    team: {
        list: '/team/list',
        create: '/team/create',
        view: (teamId: string | number) => `/team/${teamId}`,
    },

    // SESSIONS
    session: {
        list: '/session/list',
    },

    // USERS
    users: {
        list: '/users/list',
        view: (userId: string | number) => `/users/${userId}`
    },

    // CATAPULT
    catapult: {
        root: '/catapult'
    },

    // ADMIN
    admin: {

        root: '/admin',

        // USERS
        // users: {
        //     root: '/admin/users',
        //     view: (userId: string | number) => `/admin/users/${userId}`
        // },

        // CLIENTS
        clients: {
            root: '/admin/clients',
            list: '/admin/clients/list',
            view: {
                root: (clientId: string | number) => `/admin/clients/${clientId}`,
                user: (clientId: string | number, userId: string | number) => `/admin/clients/${clientId}/users/${userId}`,
            }
        }
    }
}
