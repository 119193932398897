import * as yup from 'yup';

export enum loginErrorEnum {
    credentials = 'Incorrect Credentials',
    unknown = 'An unknown error occurred.  Please refresh your browser and try again',
    precondition = 'Two-factor authentication is required'
}

/**
 * Login request validation schema
 * @returns Yup Schema
 */
export const loginSchema = yup.object({
    email: yup.string().email('Must be a valid email').required('Email is required'),
    password: yup.string().required('Password is required')
})

export interface ILogin2FACredentials {
        id: number;
        is_otp_enabled: boolean;
        email: {
            email_address: string;
            is_verified: boolean;
        },
        phone: {
            phone_number: string;
            is_verified: boolean;
        },
        qrcode: {
            is_qrcode_verified: boolean;
        }
        token: string;
}
