import { EAccount2FAMethods } from "@/src/components/modules/account/form/Account2FA/Account2FA.types";
import { GET, IParamsInterface } from "@/src/network/fetchers";
import { IBaseApiResponse } from "@/src/network/network.types";

// ROUTE
export const SEND_OTP = '/send-otp';

/**
 *
 * Send OTP
 *
 * @param params
 * @param accessToken
 * @returns
 */
export const sendOTP = async(params?: IParamsInterface, accessToken?: string | false): Promise<IBaseApiResponse<unknown>> => {

    try {
        const res = await GET(SEND_OTP, accessToken, params, undefined);

        return {
            payload: res?.payload,
            success: res?.success,
            errors: res?.errors,
        }
    } catch (err) {
        return {
            payload: undefined,
            success: false,
            errors: undefined,
        }
    }
}
