import {IListReqParams} from "../network.types";

/**
 * Generates stringified URLSearchParams for specified params object
 * 
 * @param params - Query params
 * @returns A URL-safe query param string
 */
export const generateParams = (params?: IListReqParams) => {

    if(!params) { return '' }

    const p = new URLSearchParams(params as Record<string, string>);

    return `?${p.toString()}`;

}