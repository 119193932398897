'use client'
import { LoginContextProvider } from "../LoginContext";
import { useRouter } from "next/navigation";
import { LoginForms } from "./LoginForms";

export const Login = () => {
    const router = useRouter();

    return (
        <LoginContextProvider router={router}>
            <LoginForms />
        </LoginContextProvider>
    )
}