import { useContext } from "react"
import { LoginContext } from "../LoginContext"
import { Login2FA } from "../2FA/Login2FA";
import { Login1FA } from "../1FA/Login1FA";

export const LoginForms = () => {
    const { credentials } = useContext(LoginContext);
    return (
        <>
            {credentials && credentials?.is_otp_enabled ? (
                <Login2FA />
            ) : (
                <Login1FA />
            )}
        </>

    )
}