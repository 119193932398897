import { FormInput } from '@/src/components/external/Form/FormInput/FormInput';
import { FormCodeInput } from '@/src/components/external/Form/FormCodeInput/FormCodeInput';
import { FormPasswordInput } from '@/src/components/external/Form/FormPasswordInput/FormPasswordInput';
import { Login } from '@mui/icons-material';
import { Alert, Box, Button, Grow, Stack, Typography } from '@mui/material';
import Link from 'next/link';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { pageRoutes } from '../../../../../../network/pageRoutes/pageRoutes';
import { LoginContext } from '../../LoginContext';
import { EAccount2FAMethods } from '@/src/components/modules/account/form/Account2FA/Account2FA.types';
import { enqueueSnackbar } from 'notistack';
import { sendOTP } from '@/src/network/api/auth/otp/sendOTP/sendOTP';
import { obfuscateEmail, obfuscatePhoneNumber } from '@/src/utils/obfuscate';


/**
 * The form for the Login.tsx componet
 * @returns Component
 */
export const Login2FAForm = () => {
    // CONTEXT
    const { credentials, method, resendCounter, countdown, changeMethod, changeResendCounter, changeCountdown, handle2FALogin } = useContext(LoginContext)

    // STATE
    const [loading, setLoading] = useState(false);

    // FORM
    const formInstance = useFormContext();
    const { handleSubmit, formState: { isSubmitting, errors } } = formInstance

    // @ts-ignore - Using any here because User Object changes based on form data IE. MFA type
    const onSubmit = async (data: any) => {
        handle2FALogin(data, formInstance);
    };
    
    // HANDLERS
    const handleValueChange = (event: Event, value: string) => {
        if (method !== EAccount2FAMethods.BACKUP && value.length === 6) {
            handleSubmit(onSubmit)()
        }
        if (method === EAccount2FAMethods.BACKUP && value.length === 8) {
            handleSubmit(onSubmit)()
        }
    };

    const handleResendOTP = async () => {
        if(method === EAccount2FAMethods.QRCODE) return;

        setLoading(true);
        const res = await sendOTP({method: method!}, credentials?.token);

        if(!res?.success) {
            enqueueSnackbar({
                message: 'Failed to resend OTP. Please try again later',
                variant: 'error'
            });
        }

        if(res?.success) {
            changeResendCounter(1);
            enqueueSnackbar({
                message: 'OTP sent',
                variant: 'success'
            });
        }
        setLoading(false);
    }

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
        if (resendCounter > 0 && countdown > 0) {
            interval = setInterval(() => {
                changeCountdown(countdown - 1);
            }, 1000);
        } else if (countdown <= 0) {
            clearInterval(interval!);
            changeCountdown(60); // Reset countdown
            changeResendCounter(0); // Reset counter
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [resendCounter, countdown]);

    return (
        <Box height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
            <Stack alignItems={'center'} spacing={4}>
                <Typography variant='h4' mb={2}>Two-factor Authentication</Typography>

                {/* FIELDS */}
                <FormCodeInput 
                    name={'otp_code'}
                    disabled={isSubmitting}
                    allowedCharacters={method === EAccount2FAMethods.BACKUP ? 'alphanumeric' : 'numeric'}
                    length={method === EAccount2FAMethods.BACKUP ? 8 : 6}
                    onValueChange={(e, v) => handleValueChange(e, v)}
                />
                
                <Typography textAlign={'center'} variant='bodyGrey'>
                    {method === EAccount2FAMethods.QRCODE && (
                        `A verification code will be available on your authentication device. Enter the code to continue`
                    )}

                    {method === EAccount2FAMethods.EMAIL && (
                        `A message with a verification code has been sent to your email ${credentials?.email ? obfuscateEmail(credentials?.email.email_address): ''}. Enter the code to continue`
                    )}

                    {method === EAccount2FAMethods.PHONE && (
                        `A message with a verification code has been sent to your phone ${credentials?.phone ? obfuscatePhoneNumber(credentials?.phone.phone_number) : ''}. Enter the code to continue`
                    )}

                    {method === EAccount2FAMethods.BACKUP && (
                        `Enter your backup code to continue`
                    )}
                </Typography>

                {method === EAccount2FAMethods.QRCODE && (
                    <Button color='secondary' onClick={() => changeMethod(EAccount2FAMethods.BACKUP)}>I want to use a backup code</Button>
                )}

                {method === EAccount2FAMethods.BACKUP && (
                    <Button color='secondary' onClick={() => changeMethod(EAccount2FAMethods.QRCODE)}>I want to use my Authenticator App</Button>
                )}

                {method !== EAccount2FAMethods.QRCODE && method !== EAccount2FAMethods.BACKUP && (
                    <Button color='secondary' 
                        onClick={handleResendOTP} 
                        disabled={loading || (resendCounter > 0 && countdown !== 60)}
                    >
                        Resend code {resendCounter > 0 && countdown !== 60 ? `(${countdown}s)` : ''}
                    </Button>
                )}
                
                {/* ERROR */}
                <Grow in={!!errors['api']}>
                    <Alert 
                        severity={'error'}
                        variant='filled'
                    >
                        {errors['api']?.message as string || 'An unknown error has occurred'}
                    </Alert>
                </Grow>
            </Stack>
           
            {/* CONTROLS */}
            <Stack direction={'row'} alignItems={'center'} gap={1} mt={3} height={'fit-content'}>
                <Button startIcon={<Login />} color='secondary' disabled={isSubmitting} type='submit' variant='contained'>Verify</Button>    
                <Button color='secondary' onClick={()=> changeMethod(null)}>Try another method</Button>
            </Stack>
        </Box>
    )

}
