'use client';

import { createContext, useContext, useState } from "react";

interface FormProviderContext {
    loading: boolean;
    setLoading: (loading: boolean) => void;
}

const formContext = createContext<FormProviderContext>({
    loading: false,
    setLoading: () => {},
});

export const useFormData = () => useContext(formContext);

export const useFormLoading = () => {
    const { loading } = useFormData();
    return loading;
};

export const FormDataProvider = ({ children }: { children: React.ReactNode }) => {

    // STATE
    const [loading, setLoading] = useState(false);

    const values = { loading, setLoading };

    return <formContext.Provider value={values}>{children}</formContext.Provider>;
};

export default { formContext, FormDataProvider };
