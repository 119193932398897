'use client';
import {
    FieldValues,
    FormProvider,
    SubmitHandler,
    useForm,
    useFormContext, UseFormReturn,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {IApiError} from "./Form.types";
import { enqueueSnackbar } from "notistack";

export interface FormProps<T extends FieldValues> {
    children: JSX.Element | JSX.Element[];
    onSubmit: (data: T, formInstance?: UseFormReturn) => Promise<IApiError | void>;
    styles?: React.CSSProperties;
    defaultValues?: any;
    schema?: any;
    submitOnEnter?: boolean;
    onSubmitFailure?: (errors: IApiError | string[]) => void;
}

export interface FormInnerProps<T extends FieldValues> extends FormProps<T>{
    form: UseFormReturn<T, any>
}

export const generateErrorMessage = (errors: string[] | string | object | undefined | null): string | undefined => {

    if(!errors) {
        return undefined
    }

    if(typeof errors === 'string') {
        return errors
    }

    let str = '';

    if(errors && Array.isArray(errors)) {
        errors.forEach(msg => {
            if(typeof msg === 'string') {
                str += `${msg} \n`;
            }
        });

        return str;
    }


    if(typeof errors === 'object') {
        Object.entries(errors).forEach(([key, value]) => {
            if(typeof value === 'string') {
                str += `${key}: ${value}, \n`
            }
        })
    }

    return str;
}


function FormInner<T extends FieldValues>({ styles, children, onSubmit, form, submitOnEnter, onSubmitFailure }: FormInnerProps<T>) {

    const formInstance = useFormContext();

    const _onSubmit: SubmitHandler<T> = async (data: any) => {
        try {
            const errors = await onSubmit(data, formInstance) as IApiError | string[];
            if(errors) {

                if(Array.isArray(errors)) {
                    enqueueSnackbar({
                        message: generateErrorMessage(errors),
                        variant: 'error'
                    })
                    formInstance.setError('api', { type: 'api', message: 'Unknown Errors' })
                }

                Object.entries(errors).forEach(([key, values]) => {
                    formInstance.setError(key, { type: 'api', message: generateErrorMessage(values) })
                });

                if(onSubmitFailure) {
                    onSubmitFailure(errors);
                }

            }
        } catch (error: unknown) {
            console.error("An uncaught error has occurred during Form Submission");
            console.debug("Error details", error);
        }
    };

    const handlePreventDefault = (e: React.KeyboardEvent<HTMLFormElement>) => {
        if(submitOnEnter) { return }
        if(e.key === 'Enter') {
            e.preventDefault();
        }
    }

    return (
        <form style={styles} onSubmit={form.handleSubmit(_onSubmit)} onKeyDown={handlePreventDefault}>
            {children}
        </form>
    )
}

export function Form<T extends FieldValues>(props: FormProps<T>) {

    // FORM
    const form = useForm<T>({
        defaultValues: props.defaultValues,
        resolver: props.schema ? (yupResolver(props.schema) as any) : undefined,
    });

    return (
        <FormProvider {...form}>
            <FormInner {...props} form={form}>
                {props.children}
            </FormInner>
        </FormProvider>
    );
}

export default { generateErrorMessage, Form };
