'use client';
import { useFormLoading } from '../context/FormContext';
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { HTMLInputTypeAttribute, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormGroup, IconButton, InputBaseComponentProps, InputLabel, InputProps } from '@mui/material';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export interface FormPasswordInputProps {
    label: string;
    name: string;
    placeholder?: string;
    required?: boolean;
    type?: HTMLInputTypeAttribute;
    TextFieldProps?: TextFieldProps;
    InputProps?: InputProps;
    inputProps?: InputBaseComponentProps;
    inputLabelProps?: object;
    value?: string;
    props?: TextFieldProps;
    defaultValue?: string;  
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

export const FormPasswordInput = ({
    label,
    name,
    type = "text",
    placeholder,
    required,
    value,
    props,
    defaultValue,
    onChange,
    disabled,
    inputLabelProps,
    inputProps,
    TextFieldProps
}: FormPasswordInputProps) => {

    // FORM
    const { control, formState: { errors } } = useFormContext();
    const isLoading = useFormLoading();

    // STATE
    const [visible, setVisible] = useState(false);

    // FUNCS
    const interceptChange = (e: any, changeFunc: any) => {
        changeFunc(e);
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={value || ""}
            render={({ field }) => (
                <FormGroup>
                    <InputLabel>
                        {label}
                    </InputLabel>
                    <TextField
                        {...props}
                        {...field}
                        {...TextFieldProps}
                        disabled={isLoading || disabled}
                        variant="filled"
                        required={required}
                        type={visible ? "text" : "password"}
                        placeholder={placeholder}
                        fullWidth={true}
                        InputLabelProps={inputLabelProps}
                        InputProps={{
                            endAdornment: (
                                <IconButton sx={{ p: 0 }} onClick={() => setVisible(!visible)}>
                                    {visible ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            ),
                        }}
                        inputProps={inputProps}
                        onChange={(e) => interceptChange(e, field.onChange)}
                        helperText={errors[name]?.message ? `${errors[name]?.message}` : " "}
                        error={!!errors[name]}
                    />
                </FormGroup>

            )}
        />
    );
};

export default FormPasswordInput;
