import React from 'react';
import { useRef } from 'react';
import { AuthCodeRef } from './AuthCode';
import { Controller, useFormContext } from 'react-hook-form';
import AuthCode from './AuthCode';
import { useFormLoading } from "../context/FormContext";
import { FormCodeInputProps } from "./FormCodeInput.types"

export const FormCodeInput = ({ name, disabled, isPassword, allowedCharacters, length, onValueChange }: FormCodeInputProps) => {
    // FORM CONTEXT
    const {control} = useFormContext();
    const isLoading = useFormLoading();

    // REF
    const AuthInputRef = useRef<AuthCodeRef>(null);

    
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <AuthCode
                    ref={AuthInputRef}
                    isPassword={isPassword}
                    allowedCharacters={allowedCharacters}
                    length={length}
                    disabled={isLoading || disabled}
                    onChange={(event, value) => {
                        field.onChange(value);
                        onValueChange(event, value);
                    }}
                />
            )}
        />
    );
};
