import { FormInput } from '@/src/components/external/Form/FormInput/FormInput';
import { FormPasswordInput } from '@/src/components/external/Form/FormPasswordInput/FormPasswordInput';
import { Login } from '@mui/icons-material';
import { Alert, Box, Button, Grow, Stack, Link } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { pageRoutes } from '../../../../../../network/pageRoutes/pageRoutes';

/**
 * The form for the Login.tsx componet
 * @returns Component
 */
export const Login1FAForm = () => {

    // FORM
    const { formState: { isSubmitSuccessful, isSubmitting, errors } } = useFormContext();

    return (
        <Box height={'100%'}>

            {/* FIELDS */}
            <Box>
                <FormInput 
                    label={'Email'} 
                    name={'email'}
                    disabled={isSubmitting || isSubmitSuccessful}
                />
                <FormPasswordInput 
                    label={'Password'} 
                    name={'password'} 
                    disabled={isSubmitting || isSubmitSuccessful}
                />

                {/* ERROR */}
                <Grow in={!!errors['api']}>
                    <Alert 
                        severity={'error'}
                        variant='filled'
                    >
                        {errors['api']?.message as string || 'An unknown error has occurred'}
                    </Alert>
                </Grow>
            </Box>
           
            {/* CONTROLS */}
            <Stack direction={'column'} alignItems={'start'} gap={3}>
                <Stack direction={'row'} alignItems={'center'} gap={1} mt={3}>
                    <Button startIcon={<Login />} color='secondary' disabled={isSubmitting || isSubmitSuccessful} type='submit' variant='contained'>Log In</Button>    
                    <Link href={pageRoutes.auth.forgotPassword}>
                        <Button color='secondary'>Forgot Password</Button>
                    </Link>
                </Stack>
                <Link alignSelf={'end'} variant={'caption'} underline={'none'} color={'info'} href={pageRoutes.legal.privacyPolicy}>Privacy Policy</Link>
            </Stack>

        </Box>
    )

}
