'use client'
import { ILogin2FACredentials, loginErrorEnum } from './Login/Login.types';
import { UseFormReturn } from 'react-hook-form';
import { signIn } from 'next-auth/react';
import { login2FAErrorEnum } from './2FA/Login2FA.types';
import { pageRoutes } from '@/src/network/pageRoutes/pageRoutes';
import { createContext, useState } from 'react';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { EAccount2FAMethods } from '../../account/form/Account2FA/Account2FA.types';


export interface ILoginContextInterface {
    credentials: ILogin2FACredentials | null;
    method: EAccount2FAMethods | null;
    countdown: number,
    resendCounter: number,
    changeResendCounter: (counter: number) => void;
    changeCountdown: (countdown: number) => void;
    changeCredentials: (credentials: ILogin2FACredentials) => void;
    changeMethod: (method: EAccount2FAMethods | null) => void;
    handle2FALogin: (data: {otp_code: string}, formInstance?: UseFormReturn) => Promise<void>;
    handleLogin: (data: {email: string, password: string}, formInstance?: UseFormReturn) => Promise<void>;
}

export interface ILoginContextProviderProps {
    children: React.ReactNode;
    router: AppRouterInstance;
}

export const LoginContext = createContext<ILoginContextInterface>({
    credentials: null,
    method: null,
    countdown: 5,
    resendCounter: 0,
    changeResendCounter: () => {},
    changeCountdown: () => {},
    changeCredentials: () => {},
    changeMethod: () => {},
    handle2FALogin: async () => {},
    handleLogin: async () => {}
});

export const LoginContextProvider = ({children, router}: ILoginContextProviderProps) => {

    const [credentials, setCredentials] = useState<ILogin2FACredentials | null>(null);
    const [method, setMethod] = useState<EAccount2FAMethods | null>(null);
    const [countdown, setCountdown] = useState(5);
    const [resendCounter, setResendCounter] = useState(0);

    const changeCredentials = (credentials: ILogin2FACredentials) => {
        setCredentials(credentials);
    }

    const changeMethod = (method: EAccount2FAMethods | null) => {
        setMethod(method);
    }

    const changeResendCounter = (counter: number) => {
        setResendCounter(counter);
    }

    const changeCountdown = (countdown: number) => {
        setCountdown(countdown);
    }

    const handle2FALogin = async ({otp_code}: {otp_code: string}, formInstance?: UseFormReturn) => {

        if(formInstance) {
            formInstance.clearErrors();
        }
        

        await signIn("2fa", {
            token: credentials?.token,
            otp_code: otp_code,
            method: method,
            redirect: false,
        }).then((res) => {
                if (!res?.ok || res?.error) {
                    if (res?.error === "CredentialsSignin") {
                        if(process.env.DEBUG) {
                            setTimeout(() => {
                                console.log(
                                    "%c" +
                                    "⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣠⣤⣤⣤⣤⣤⣶⣦⣤⣄⡀⠀⠀⠀⠀⠀⠀⠀⠀\n" +
                                    "⠀⠀⠀⠀⠀⠀⠀⠀⢀⣴⣿⡿⠛⠉⠙⠛⠛⠛⠛⠻⢿⣿⣷⣤⡀⠀⠀⠀⠀⠀\n" +
                                    "⠀⠀⠀⠀⠀⠀⠀⠀⣼⣿⠋⠀⠀⠀⠀⠀⠀⠀⢀⣀⣀⠈⢻⣿⣿⡄⠀⠀⠀⠀\n" +
                                    "⠀⠀⠀⠀⠀⠀⠀⣸⣿⡏⠀⠀⠀⣠⣶⣾⣿⣿⣿⠿⠿⠿⢿⣿⣿⣿⣄⠀⠀⠀\n" +
                                    "⠀⠀⠀⠀⠀⠀⠀⣿⣿⠁⠀⠀⢰⣿⣿⣯⠁⠀⠀⠀⠀⠀⠀⠀⠈⠙⢿⣷⡄⠀\n" +
                                    `⠀⠀⣀⣤⣴⣶⣶⣿⡟⠀⠀⠀⢸⣿⣿⣿⣆⠀   403   ⠀ ⣿⣷⠀\n` +
                                    "⠀⢰⣿⡟⠋⠉⣹⣿⡇⠀⠀⠀⠘⣿⣿⣿⣿⣷⣦⣤⣤⣤⣶⣶⣶⣶⣿⣿⣿⠀\n" +
                                    "⠀⢸⣿⡇⠀⠀⣿⣿⡇⠀⠀⠀⠀⠹⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠃⠀\n" +
                                    "⠀⣸⣿⡇⠀⠀⣿⣿⡇⠀⠀⠀⠀⠀⠉⠻⠿⣿⣿⣿⣿⡿⠿⠿⠛⢻⣿⡇⠀⠀\n" +
                                    "⠀⣿⣿⠁⠀⠀⣿⣿⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⣿⣧⠀⠀\n" +
                                    "⠀⣿⣿⠀⠀⠀⣿⣿⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⣿⣿⠀⠀\n" +
                                    "⠀⣿⣿⠀⠀⠀⣿⣿⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⣿⣿⠀⠀\n" +
                                    "⠀⢿⣿⡆⠀⠀⣿⣿⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⣿⡇⠀⠀\n" +
                                    "⠀⠸⣿⣧⡀⠀⣿⣿⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣿⣿⠃⠀⠀\n" + 
                                    "⠀⠀⠀⠀⠀⠀⠀⢿⣿⣦⣄⣀⣠⣴⣿⣿⠁⠀⠈⠻⣿⣿⣿⣿⡿⠏⠀⠀⠀⠀\n" +
                                    "⠀⠀⠀⠀⠀⠀⠀⠈⠛⠻⠿⠿⠿⠿⠋⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀\n" +
                                    " [ERROR 403 - WRONG CREDENTIALS :^)]   ",
                                    "color: red",
                                );
                            }, 200);
                        }
                        if(formInstance) {
                            formInstance.setError('api', { message: login2FAErrorEnum.credentials, type: 'api' })
                        }
                    } else {
                        if(formInstance) {
                            formInstance.setError('api', { message: login2FAErrorEnum.unknown, type: 'api' })
                        }
                    }  
                } else {
                    router.push(pageRoutes.root);
                }
        }).catch((err) => {
    
            if(process.env.DEBUG) {
                console.error('ERR', err);
            }
    
            if(formInstance) {
                formInstance.setError('api', { message: login2FAErrorEnum.unknown, type: 'api' })
            }
        })
    }

    // HANDLERS
    /**
     * Handles posting email/password to login API endpoint
     * 
     * @param param0.email - Email Address
     * @param param0.password - Password
     * @param formInstance - reference to the current form instance
     */
    const handleLogin = async ({email, password}: { email: string, password: string }, formInstance?: UseFormReturn) => {
        if(formInstance) {
            formInstance.clearErrors();
        }

        await signIn("email-password", {email, password, redirect: false}).then((res) => {

            if (!res?.ok || res?.error) {
                if(res?.error && JSON.parse(res?.error).statusCode === 428) {
                    changeCredentials({...JSON.parse(res.error).payload as ILogin2FACredentials});
                    if(formInstance) {
                        formInstance.setError('api', { message: loginErrorEnum.precondition, type: 'api' })
                    }
                    return;
                }

                if (res?.error === "CredentialsSignin") {
                    if(process.env.DEBUG) {
                        setTimeout(() => {
                            console.log(
                                "%c" +
                                "⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣠⣤⣤⣤⣤⣤⣶⣦⣤⣄⡀⠀⠀⠀⠀⠀⠀⠀⠀\n" +
                                "⠀⠀⠀⠀⠀⠀⠀⠀⢀⣴⣿⡿⠛⠉⠙⠛⠛⠛⠛⠻⢿⣿⣷⣤⡀⠀⠀⠀⠀⠀\n" +
                                "⠀⠀⠀⠀⠀⠀⠀⠀⣼⣿⠋⠀⠀⠀⠀⠀⠀⠀⢀⣀⣀⠈⢻⣿⣿⡄⠀⠀⠀⠀\n" +
                                "⠀⠀⠀⠀⠀⠀⠀⣸⣿⡏⠀⠀⠀⣠⣶⣾⣿⣿⣿⠿⠿⠿⢿⣿⣿⣿⣄⠀⠀⠀\n" +
                                "⠀⠀⠀⠀⠀⠀⠀⣿⣿⠁⠀⠀⢰⣿⣿⣯⠁⠀⠀⠀⠀⠀⠀⠀⠈⠙⢿⣷⡄⠀\n" +
                                `⠀⠀⣀⣤⣴⣶⣶⣿⡟⠀⠀⠀⢸⣿⣿⣿⣆⠀   403   ⠀ ⣿⣷⠀\n` +
                                "⠀⢰⣿⡟⠋⠉⣹⣿⡇⠀⠀⠀⠘⣿⣿⣿⣿⣷⣦⣤⣤⣤⣶⣶⣶⣶⣿⣿⣿⠀\n" +
                                "⠀⢸⣿⡇⠀⠀⣿⣿⡇⠀⠀⠀⠀⠹⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠃⠀\n" +
                                "⠀⣸⣿⡇⠀⠀⣿⣿⡇⠀⠀⠀⠀⠀⠉⠻⠿⣿⣿⣿⣿⡿⠿⠿⠛⢻⣿⡇⠀⠀\n" +
                                "⠀⣿⣿⠁⠀⠀⣿⣿⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⣿⣧⠀⠀\n" +
                                "⠀⣿⣿⠀⠀⠀⣿⣿⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⣿⣿⠀⠀\n" +
                                "⠀⣿⣿⠀⠀⠀⣿⣿⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⣿⣿⠀⠀\n" +
                                "⠀⢿⣿⡆⠀⠀⣿⣿⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⣿⡇⠀⠀\n" +
                                "⠀⠸⣿⣧⡀⠀⣿⣿⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣿⣿⠃⠀⠀\n" +
                                "⠀⠀⠛⢿⣿⣿⣿⣿⣇⠀⠀⠀⠀⠀⣰⣿⣿⣷⣶⣶⣶⣶⠶⠀⢠⣿⣿⠀⠀⠀\n" +
                                "⠀⠀⠀⠀⠀⠀⠀⣿⣿⠀⠀⠀⠀⠀⣿⣿⡇⠀⣽⣿⡏⠁⠀⠀⢸⣿⡇⠀⠀⠀\n" +
                                "⠀⠀⠀⠀⠀⠀⠀⣿⣿⠀⠀⠀⠀⠀⣿⣿⡇⠀⢹⣿⡆⠀⠀⠀⣸⣿⠇⠀⠀⠀\n" +
                                "⠀⠀⠀⠀⠀⠀⠀⢿⣿⣦⣄⣀⣠⣴⣿⣿⠁⠀⠈⠻⣿⣿⣿⣿⡿⠏⠀⠀⠀⠀\n" +
                                "⠀⠀⠀⠀⠀⠀⠀⠈⠛⠻⠿⠿⠿⠿⠋⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀\n" +
                                " [ERROR 403 - WRONG CREDENTIALS :^)]   ",
                                "color: red",
                            );
                        }, 200);
                    }
                    if(formInstance) {
                        formInstance.setError('api', { message: loginErrorEnum.credentials, type: 'api' })
                    }
                } else {
                    if(formInstance) {
                        formInstance.setError('api', { message: loginErrorEnum.unknown, type: 'api' })
                    }
                }  
            } else {
                router.push(pageRoutes.root);
            }
        })
        .catch((err) => {

            if((err as Error).cause === '2fa') {
                if(formInstance) {
                    formInstance.setError('api', { message: loginErrorEnum.precondition, type: 'api' })
                }
                return;
            }

            if(process.env.DEBUG) {
                console.error('ERR', err);
            }

            if(formInstance) {
                formInstance.setError('api', { message: loginErrorEnum.unknown, type: 'api' })
            }
        })
    }


    return (
        <LoginContext.Provider value={{
            credentials,
            method,
            countdown,
            resendCounter,
            changeCredentials,
            changeMethod,
            changeResendCounter,
            changeCountdown,
            handle2FALogin,
            handleLogin
        }}>
            {children}
        </LoginContext.Provider>

    );

}

