export function obfuscateEmail(email: string): string {
    const [localPart, domainPart] = email.split('@');
    if (localPart.length <= 2) {
        // If the local part is too short to obfuscate, return it as is
        return email;
    }
    const obfuscatedLocalPart = localPart[0] + '*'.repeat(localPart.length - 2) + localPart[localPart.length - 1];
    return `${obfuscatedLocalPart}@${domainPart}`;
}

export function obfuscatePhoneNumber(phoneNumber: string): string {
    const lastFourDigits = phoneNumber.slice(-4);
    return `******${lastFourDigits}`;
}

