'use client'
import { Form } from '@/src/components/external/Form/Form';
import React, { useContext } from 'react';
import { Login2FAForm } from './form/Login2FAForm';
import { login2FASchema } from './Login2FA.types';
import { Box, CircularProgress, Typography, Stack, Link, Button } from '@mui/material';
import { PhonelinkLock, Mail, Sms, KeyboardArrowRight } from '@mui/icons-material';
import { LoginContext } from '../LoginContext';
import { EAccount2FAMethods } from '../../../account/form/Account2FA/Account2FA.types';
import { enqueueSnackbar } from 'notistack';
import { sendOTP } from '@/src/network/api/auth/otp/sendOTP/sendOTP';
import { useFormContext } from 'react-hook-form';

export const Login2FA = () => {
    // CONTEXT
    const { credentials, method, resendCounter, changeMethod } = useContext(LoginContext)

    // STATE
    const handleMethodSelect = async (method: EAccount2FAMethods) => {
        changeMethod(method);
        if (method !== EAccount2FAMethods.QRCODE) {
            if (resendCounter > 0) return;
            
            const res = await sendOTP({method}, credentials?.token);

            if(!res?.success) {
                enqueueSnackbar({
                    message: 'Failed to resend OTP. Please try again later',
                    variant: 'error'
                });
            }
        }
    }

    return (
        <>
        {method ? (
            <Login2FAInput/>
        ) : (
        <Box height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
            <Stack alignItems={'center'} spacing={2}>
                <Typography variant='h4' mb={2}>Two-factor Authentication</Typography>
                <Typography variant='bodyGrey'>Choose your authentication method</Typography>

                {credentials && credentials.qrcode.is_qrcode_verified && (
                    <Box sx={{cursor: 'pointer', '&:hover': {backgroundColor: '#eee'}}} onClick={() => handleMethodSelect(EAccount2FAMethods.QRCODE)} width={'100%'} p={2} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                        <Stack spacing={2} direction={'row'} width={'100%'} alignItems={'center'}>
                            <PhonelinkLock />
                            <Typography variant='h5'>Use your Authenticator App</Typography>
                        </Stack>
                        <KeyboardArrowRight />
                    </Box>
                )}
                
                {credentials && credentials.email.is_verified && (
                    <Box sx={{cursor: 'pointer', '&:hover': {backgroundColor: '#eee'}}} onClick={() => handleMethodSelect(EAccount2FAMethods.EMAIL)} width={'100%'} p={2} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                        <Stack spacing={2} direction={'row'} width={'100%'} alignItems={'center'}>
                        <Mail />
                            <Typography variant='h5'>Receive a code by Email</Typography>
                        </Stack>
                        <KeyboardArrowRight />
                    </Box>
                )}

                {credentials && credentials.phone.is_verified && (
                    <Box sx={{cursor: 'pointer', '&:hover': {backgroundColor: '#eee'}}} onClick={() => handleMethodSelect(EAccount2FAMethods.PHONE)} width={'100%'} p={2} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                        <Stack spacing={2} direction={'row'} width={'100%'} alignItems={'center'}>
                        <Sms />
                            <Typography variant='h5'>Receive a code by SMS</Typography>
                        </Stack>
                        <KeyboardArrowRight />
                    </Box>
                )}
            </Stack>

            {/* CONTROLS */}
            <Stack direction={'row'} alignItems={'center'} gap={1} mt={3}>
                <Link href={'/'}>
                    <Button color='secondary'>Back</Button>
                </Link>
            </Stack>
        </Box>
        )}
        </>
    )
}
    

/**
 * The primary login component
 * @returns Component
 */
export const Login2FAInput = () => {
    const { handle2FALogin } = useContext(LoginContext)


    return (
        <Form onSubmit={handle2FALogin} submitOnEnter={true} schema={login2FASchema} styles={{ height: '100%', width: '100%' }}>
            <Login2FAContent />
        </Form>
    )

}

/**
 * Switches between displaying login form, or loading state (if successful sign in)
 * 
 * @returns Component
 */
const Login2FAContent = () => {

    // FORM
    const { formState: { errors, isSubmitSuccessful } } = useFormContext();
    console.log(!!errors['api'])

    if(!(!!errors['api']) && isSubmitSuccessful) {
        return (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'} gap={2}>
                <CircularProgress />
                <Typography variant='h5'>Preparing your Dashboard</Typography>
            </Box>
        )
    }

    return (
        <>
            <Login2FAForm />
        </>
      
    )
}
