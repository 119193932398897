import * as yup from 'yup';

export enum login2FAErrorEnum {
    credentials = 'Incorrect Credentials',
    unknown = 'An unknown error occurred.  Please refresh your browser and try again'
}

/**
 * Login request validation schema
 * @returns Yup Schema
 */
export const login2FASchema = yup.object({
    otp_code: yup.string().required('OTP Code is required')
})