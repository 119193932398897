import { getApiUrl } from "./networkAdapter";
import { IListReqParams } from "./network.types";
import { generateParams } from "./utils/generateParams";
import { AxiosError } from 'axios';

export type IParamsInterface = {  [key: string]: string | number | boolean | null | undefined } & IListReqParams

const hasRequirements = (route: string, accessToken: string | boolean | undefined) => {
    // No route or token, kill function
    if(!route || accessToken === undefined) {
        return false
    }

    // Has route, but deliberately unauthenticated request (accessToken is false)
    else if (accessToken === false) {
        return true
    }

    // All good to continue
    return true
}

export class requestHandler {

    // REQUEST PROTOCOL
    _protocol: string = 'GET';

    constructor(protocol: string) {
       this._protocol = protocol;
       this.request = this.request.bind(this);
       this.refreshSession = this.refreshSession.bind(this);
    }

    private async refreshSession() {
        try {
            const res = await fetch('/api/refresh-token');

            const body = await res.json();

            return body;

        } catch (err) {
            console.log('ERR', err);
            return false
        }
    }

    public async request(
        route: string,
        accessToken?: string | false,
        params?: IParamsInterface,
        requestBody?: any,
        opts?: RequestInit,
        fullRoutePath?: boolean
    ) {
        try {

            if(!hasRequirements(route, accessToken)) {
                return false
            }

            const isFormData = requestBody instanceof FormData;

            const headers: HeadersInit = accessToken ? {
                Authorization: `Bearer ${accessToken}`,
            } : {};

            if(!isFormData) {
                headers['Content-Type'] = 'application/json'
            }

            const res = await fetch(`${fullRoutePath ? '' : getApiUrl()}${route}${generateParams(params)}`, {
                method: this._protocol,
                body: isFormData ? requestBody : requestBody ? JSON.stringify(requestBody) : undefined,
                headers,
                ...opts
            });

            // If access denied, always redirect to login page
            // Theoretically, the session shouldn't be allowed to expire, so this shouldn't happen often
            // if(res?.status === 401) {
            //     redirectToLogin();
            // }

            const body = await res.json();

            if(body) {
                return {...body, status: res.status}
            }

        } catch (err) {
            // if(process.env.DEBUG) {
                console.debug("Request Error:", err);
            // }
            throw {
                success: false,
                error: (err as AxiosError)?.message
            };
        }
    }

}

const getHandler = new requestHandler('GET');
export const GET = getHandler.request

// export const GET = new requestHandler('GET');
const postHandler = new requestHandler('POST');
export const POST = postHandler.request;

export const PATCH = new requestHandler('PATCH').request;
export const PUT = new requestHandler('PUT').request;
export const DELETE = new requestHandler('DELETE').request;
