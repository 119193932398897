'use client'
import { Form } from '@/src/components/external/Form/Form';
import React, { useContext } from 'react';
import { Login1FAForm } from './form/Login1FAForm';
import { useFormContext } from 'react-hook-form';
import { loginSchema } from '../Login/Login.types';
import { Box, CircularProgress, Typography } from '@mui/material';
import { LoginContext } from '../LoginContext';

/**
 * The primary login component
 * @returns Component
 */
export const Login1FA = () => {

    const { handleLogin } = useContext(LoginContext)

    return (
        <Form onSubmit={handleLogin} submitOnEnter={true} schema={loginSchema} styles={{ height: '100%', width: '100%' }}>
            <Login1FAContent />
        </Form>
    )

}

/**
 * Switches between displaying login form, or loading state (if successful sign in)
 * 
 * @returns Component
 */
const Login1FAContent = () => {

    // FORM
    const { formState: { errors, isSubmitSuccessful } } = useFormContext();

    if(!errors.api && isSubmitSuccessful) {
        return (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'} gap={2}>
                <CircularProgress />
                <Typography variant='h5'>Preparing your Dashboard</Typography>
            </Box>
        )
    }

    return (
        <>
            <Typography variant='h4' mb={2}>LOGIN</Typography>
            <Login1FAForm />
        </>
      
    )
}
