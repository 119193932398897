export enum EAccount2FAMethods {
    QRCODE = 'qrcode',
    PHONE = 'phone',
    EMAIL = 'email',
    BACKUP = 'backup'
}

export interface IQRCodePayload {
    qrcode?: string;
    key?: string;
    valid_until?: string;
}

export interface IAccount2FAFormStructure {
    method: EAccount2FAMethods;
    otp_code: string;
}
